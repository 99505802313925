import React, { useContext } from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import CartIcon from "../images/cart"
import { Message } from "./l10n"
import { CartContext } from "./cart"

const Button = styled(Link)`
  background: black;
  color: white;
  position: absolute;
  top: 97px;
  right: 30px;
  padding: 10px 20px;
  border-radius: 5px;
  @media (max-width: 599px) {
    position: fixed;
    z-index: 1;
    top: 70px;
  }
`
const Text = styled.span`
  margin-left: 15px;
  @media (max-width: 599px) {
    display: none;
  }
`

const CartButton = () => {
  const { cart } = useContext(CartContext)
  if (!cart.products.length) {
    return null
  }
  return (
    <Button to="/cart/">
      <CartIcon fill="white" />
      <strong>{cart && cart.products.reduce((prev, { quantity }) => prev + quantity, 0)}</strong>
      <Text>
        <Message id="checkout" />
      </Text>
    </Button>
  )
}

export default CartButton
