import React, { useContext } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import styled from "styled-components"

import { LangContext, Message } from "../components/l10n"
import { ICategory } from "../graph"
import Instagram from "../images/instagram"

const Container = styled.section`
  margin: 22px 50px;
  a {
    display: block;
    padding: 5px;
    white-space: nowrap;
  }
  a.active {
    color: #f5a623;
  }
  @media (max-width: 800px) {
    margin: 40px 20px 0 30px;
    a {
      padding: 8px;
    }
  }
`
const SocialLink = styled.a`
  margin: 32px 0;
`

const Menu = () => {
  const { lang } = useContext(LangContext)

  const data = useStaticQuery(graphql`
    query Menu {
      categories: allContentfulProductCategory {
        edges {
          node {
            id
            node_locale
            name
            slug
          }
        }
      }
    }
  `)

  const categories: ICategory[] = data.categories.edges
    .map(({ node }) => node)
    .filter(({ node_locale }) => node_locale.indexOf(lang) === 0)

  return (
    <Container>
      <Link to="/shop/" activeClassName="active">
        <Message id="all" />
      </Link>
      {categories.map(({ slug, name }) => (
        <Link key={slug} to={`/${slug}/`} activeClassName="active">
          {name}
        </Link>
      ))}
      <SocialLink href="https://www.instagram.com/slowprint.fr/" title="Instagram">
        <Instagram />
      </SocialLink>
      <Link to="/about/" activeClassName="active">
        <Message id="about" />
      </Link>
    </Container>
  )
}

export default Menu
