import styled, { css } from "styled-components"

export const buttonCss = css`
  margin: 30px 0 50px;
  border: 1px solid #d8d8d8;
  padding: 20px 30px;
  text-transform: uppercase;
  font-size: 20px;
  :hover {
    border-color: black;
  }
  svg {
    vertical-align: -5px;
    margin-right: 5px;
  }
  @media (max-width: 999px) {
    margin: 20px 0px 30px;
  }
  @media (max-width: 399px) {
    font-size: 16px;
    padding: 15px 20px;
    svg {
      width: 18px;
      height: 18px;
      vertical-align: -3px;
    }
  }
`

export const Content = styled.section`
  flex: 1;
  margin: 0 30px;
  @media (max-width: 499px) {
    margin: 0 15px;
  }
`
export const Title = styled.h1`
  font-size: 2rem;
  margin: 18px 0 20px -2px;
  @media (max-width: 499px) {
    font-size: 1.5rem;
  }
`
