import React, { ReactNode, useState, useEffect } from "react"
import styled from "styled-components"

import CartButton from "../components/cartbutton"
import SEO from "./seo"
import Logo from "../images/logo"
import { Link } from "gatsby"
import Hamburger from "../images/hamburger"
import Menu from "../components/menu"
import { Message } from "../components/l10n"
import { IOgImage } from "../graph"

const Header = styled.header`
  text-align: center;
  margin: 30px;
  @media (max-width: 399px) {
    margin: 10px;
    svg {
      width: 80px;
    }
  }
`
const Title = styled.h1`
  font-size: 20px;
  color: #9b9b9b;
  margin: 20px 0;
  @media (max-width: 399px) {
    font-size: 18px;
  }
`
const Container = styled.main`
  display: flex;
  @media (max-width: 599px) {
    display: block;
  }
  margin-bottom: 30px;
  min-height: 50vh;
`
const Drawer = styled.div<{ open: boolean }>`
  position: fixed;
  background: white;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 50px 20px;
  transition: transform 0.3s ease-in-out;
  transform: translateX(${(props) => (props.open ? 0 : "-100vw")});
  @media (min-width: 400px) {
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.2);
    right: initial;
    width: 250px;
    transform: translateX(${(props) => (props.open ? 0 : "-310px")});
  }
  @media (min-width: 800px) {
    display: none;
  }
`
const Desktop = styled.div`
  @media (max-width: 799px) {
    display: none;
  }
`
const Footer = styled.footer`
  margin: 60px 20px 30px;
  text-align: center;
  font-size: 14px;
`

interface Props {
  cart?: boolean
  children: ReactNode
  title?: string
  image?: IOgImage
  noindex?: boolean
  legal?: boolean
}

const Layout = ({ cart, children, legal, ...props }: Props) => {
  const [openMenu, setOpenMenu] = useState(false)

  const toggleMenu = () => {
    window.scroll(0, 0)
    document.body.classList[openMenu ? "remove" : "add"]("noscroll")
    setOpenMenu(!openMenu)
  }

  useEffect(() => {
    document.body.classList.remove("noscroll")
  }, [])

  return (
    <>
      <SEO {...props} />
      <Header>
        <Title>Isabel Bibriesca</Title>
        <Link to="/">
          <Logo />
        </Link>
      </Header>
      {cart && <CartButton />}
      <Container>
        <Desktop>
          <Menu />
        </Desktop>
        {children}
      </Container>
      <Footer>
        {legal ? null : (
          <Link to="/legal/">
            <Message id="footer" />
          </Link>
        )}
      </Footer>
      <Hamburger active={openMenu} onClick={toggleMenu} />
      <Drawer open={openMenu}>
        <Menu />
      </Drawer>
    </>
  )
}

export default Layout
