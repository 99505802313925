import React from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import { IOgImage } from "../graph"

interface Props {
  title?: string
  image?: IOgImage
  noindex?: boolean
}

const SEO = ({ title, image, noindex }: Props) => {
  const { site } = useStaticQuery(
    graphql`
      query SEO {
        site {
          siteMetadata {
            title
            description
            keywords
            siteUrl
          }
        }
      }
    `
  )

  const { title: siteName, description, keywords, siteUrl } = site.siteMetadata

  const pageTitle = title ? `${title} | ${siteName}` : siteName

  if (!image) {
    image = {
      src: `${siteUrl}/og-logo.png`,
      width: 800,
      height: 800,
    }
  }

  const meta = [
    {
      name: `description`,
      property: `og:description`,
      content: description,
    },
    {
      property: `og:title`,
      content: pageTitle,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `keywords`,
      content: keywords,
    },
    {
      property: `og:site_name`,
      content: siteName,
    },
    {
      property: `og:image`,
      content: image.src,
    },
    {
      property: `og:image:width`,
      content: image.width,
    },
    {
      property: `og:image:height`,
      content: image.height,
    },
  ]

  if (noindex) {
    meta.push({
      name: `robots`,
      content: `noindex`,
    })
  }

  return <Helmet title={pageTitle} meta={meta} />
}

export default SEO
