import React from "react"

const Logo = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={113} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 666 526" aria-label="SlowPrint" {...props}>
    <circle cx="356.9" cy="127.6" r="119.1" fill="#fac3ac" />
    <path
      d="M461.2 344.9c-62.4-61.3-143.4-129.1-171.9-146.8-10.6-6.6-22-12.9-34.5-13-17.4-.1-32.3 11.7-45.5 23-34.2 29.2-70 58.9-125.1 95.9-22.5 15.1-42.2 28.7-59.6 40.9"
      fill="#231f20"
      stroke="#fff"
      strokeWidth={6.8513}
      strokeMiterlimit={10}
    />
    <path
      d="M167 347.8l165.5-88.3c33.8-22.6 99.8-64.3 129.4-65.9 24.9-1.3 52.1 36.6 70.9 53 23.9 20.9 41.7 40.7 65.8 59.1 9 6.8 48 35 58.2 39.8"
      fill="#231f20"
      stroke="#fff"
      strokeWidth={6.8513}
      strokeMiterlimit={10}
    />
    <path
      d="M532 39.3c1.4 0 2.8 1 3 2.6l4.4 47.6c.2 2.2.8 3.8 1.6 3.8s1.6-1.6 1.8-3.8l4.8-47.6c.4-1.6 1-2.6 2.4-2.6h20.5c1 0 2.2 1 2.4 2.2l5.2 48c.2 2.2.8 3.8 1.6 3.8s1.6-1.6 1.8-3.8l4-47.8c.2-1.4 1-2.4 2.2-2.4h32.6c2 0 2.6 1.2 2.4 2.8L604.2 187c-.2 2-1.4 3-2.6 3h-29.3c-1.2 0-2.2-1-2.4-2.2l-7.6-48.6c-.2-1.8-.8-3.2-1.6-3.2s-1.4 1.6-1.6 3.4l-7 48.2c-.4 1.4-1 2.4-2.2 2.4H520c-1.4 0-2.6-1.2-2.8-2.6L497.9 41.9c-.2-1.8.8-2.6 2.2-2.6H532zM82 91.4c-1.2 0-2-.6-2.4-2.2-1.2-4.8-3.2-7.2-6-7.2-2 0-3 1.6-3 3.6 0 6.4 9.4 13.7 21.5 21.9 13.1 8.8 22.9 23.3 22.9 38.2 0 26.9-16.1 48.4-42.4 48.4-30.5 0-44.6-22.1-44.6-54.3 0-1 .8-2 2.4-2l27.7-1.4c1.4 0 2.2.8 2.4 2.6.8 8 4.2 16.7 9 16.7 2.8 0 4.6-1.8 4.6-6.4 0-4.8-11.3-13.7-24.7-24.5S29.3 98.9 29.3 85.2c0-20.5 15.3-42.8 42.2-42.8 26.9 0 43.6 21.1 43.6 45 0 1.4-.8 2.6-2.4 2.6L82 91.4zM211.2 151.9c1.4 0 2.2 1 2.2 2v37.4c0 1.2-1 2.2-2.2 2.2h-71.9c-1.2 0-2.2-1.2-2.2-2.6V45.6c0-1.2 1.2-2.8 2.6-2.8h34c1.2 0 2 1 2 2.2v105.5c0 .8.6 1.4 1.2 1.4h34.3z"
      fill="#231f20"
    />
    <path
      d="M11.3 354.4c0-2.2 1.1-3.7 3.1-3.7h51.1c10.1 0 42.5 7.2 42.5 48.7 0 20.4-18.2 40.6-39.2 40.6-10.7 0-11.8 0-11.8 2.6V512c0 1.8-1.3 3.1-3.7 3.1H14.8c-2 0-3.5-1.8-3.5-3.9V354.4zm45.6 57.2c0 .9.7 2.4 2.2 2.4 3.3 0 4.6-2.9 4.6-7.2v-9c0-3.5-1.8-5.9-4.6-5.9-.7 0-1.1 0-1.5.2-.4.2-.7 1.1-.7 1.8v17.7zM208.6 350.7c19.3 0 42.3 14.9 42.3 41.7 0 11.8-2.2 33.5-23 36.6v.4c26.7 7.7 26.7 21.9 26.7 44.3 0 2.6 1.1 5.3 5.9 7.2 1.1.7 2 2.2 2 3.1 0 29.8-21.9 31.6-29.2 31.6-15.8 0-26.3-11-26.3-31.6v-33.8c0-7-3.1-10.5-5.5-10.5-1.3 0-1.5 1.1-1.5 2.6v70.2c0 1.3-1.1 2.6-2.6 2.6h-37.5c-1.5 0-2.6-1.1-2.6-2.8v-159c0-1.5 1.1-2.6 2.4-2.6h48.9zm-8.6 68.2c0 1.5.4 2 1.5 2 5.3 0 5.3-6.1 5.3-16.2 0-5-3.1-7.9-5-7.9-1.1 0-1.8.7-1.8 2v20.1zM314.9 515.1c-1.5 0-2.9-1.5-2.9-3.3V352.9c0-1.1.9-2.2 2-2.2h37.5c1.5 0 2.6.9 2.6 2.2v159.4c0 1.3-1.1 2.8-2.6 2.8h-36.6zM406.8 353.5c0-1.5 1.1-2.9 2.4-2.9h37.1c1.5 0 2.6.9 3.1 2.6l13.2 49.3c.4 1.8 1.1 2.6 1.5 2.6.7 0 1.3-.9 1.3-2.9v-48.9c0-1.5 1.1-2.9 2.6-2.9h32.9c1.8 0 2.8 1.5 2.8 3.1V512c0 1.5-.9 2.8-2.4 2.8h-41c-.9 0-2.2-.9-2.4-2.2l-9.6-39.5c-.4-2.2-1.3-3.1-2-3.1s-1.1.9-1.1 3.1v38.8c0 1.5-1.3 2.8-2.8 2.8H409c-1.3 0-2.2-1.3-2.2-3.1V353.5zM585.9 515.1c-1.5 0-3.1-1.3-3.1-3.1V397.6c0-1.5-1.3-2.6-2.8-2.6h-21.9c-1.3 0-2.8-1.8-2.8-3.1v-39c0-1.3.7-2.2 2-2.2H651c1.5 0 2.8.9 2.8 2.6v38.6c0 1.8-1.3 3.1-3.1 3.1h-21.9c-1.5 0-2.6 1.1-2.6 3.1v114.2c0 1.5-1.3 2.8-3.1 2.8h-37.2z"
      fill="#231f20"
    />
  </svg>
)

export default Logo
