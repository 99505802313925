import React from "react"
import Image, { FluidObject } from "gatsby-image"
import styled from "styled-components"

const Container = styled.div`
  width: 100%;
  flex-shrink: 0;
  :after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
  display: flex;
  justify-content: center;
  align-items: center;
`

interface Props {
  fluid: FluidObject
  className?: string
}

const SquareImage = ({ fluid, ...props }: Props) => {
  const style = {
    width: `${100 * Math.min(1, fluid.aspectRatio)}%`, // 100% for horizontal or square images, less for vertical
  }
  return (
    <Container {...props}>
      <Image fluid={fluid} style={style} />
    </Container>
  )
}

export default SquareImage
